<template>
  <ProcessOrderWrapper />
</template>

<script>
import ProcessOrderWrapper from '@/components/processOrder/ProcessOrderWrapper'

export default {
  name: 'ProcessOrder',
  components: {
    ProcessOrderWrapper
  }
}
</script>
